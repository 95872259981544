import React from "react";

export function Card({picture: Picture, formattedData: FormattedData, header, id, side}) {
    return (
        <div className={"card p-3 border-0 bg-transparent"} id={id}>
            { header!==null &&
            <div className={"row  p-2 text-success"}>
                <div className={"col text-start"}>
                    {
                        side === "right" ?
                            <Picture/> : <h4 className={"ms-5 text-start text-capitalize"}
                                             style={{fontVariant: "small-caps"}}>{header}</h4>
                    }
                </div>
                <div className={"col text-end"}>
                    {
                        side === "left" ?
                            <Picture/> : <h4 className={"me-5 text-end text-capitalize"}
                                             style={{fontVariant: "small-caps"}}>{header}</h4>
                    }
                </div>

            </div>
            }
            <div className={"row p-2"}>
                <div className={"col text-start"}>
                    {
                        <FormattedData/>
                    }
                </div>

            </div>
        </div>


    )
}