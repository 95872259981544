import {RotatingLines} from "react-loader-spinner";

export function LoadingAnimation() {

    return (
        <div className="d-flex align-items-center justify-content-center" style={{minHeight: '100vh'}}>
            <div className="row row-cols-1 text-center"> {/* Bootstrap class for centering content */}
                <div className={"col"}>
                    <img
                        alt="logo"
                        src={`${process.env.PUBLIC_URL}/logo_newdevs.png`}
                        className="img-fluid" // Makes the image responsive
                        style={{maxWidth: '20vw', marginBottom: '20px'}} // Adjusts size based on viewport width
                    />
                </div>
                <div className={"col"}>
                    <RotatingLines
                        visible={true}
                        height="96" // Consider adjusting size or wrapping in a responsive container
                        width="96" // Consider adjusting size or wrapping in a responsive container
                        strokeColor="#2b2d42"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                    />
                </div>
            </div>
        </div>
    )

}