
export const addressIcon=()=>{return (<i className='bx bx-home-alt-2'/>)}
export const phoneIcon=()=>{return( <i className='bx bx-phone'/>)}

export const mailIcon=()=> {return (<i className='bx bx-envelope'/>)}
export const birthdayIcon=()=> {return (<i className='bx bx-gift'/>)}
export const gitHubIcon=()=> {return (<i className='bx bxl-github'/>)}
export const webIcon=()=> {return (<i className='bx bx-window'/>)}
export const linIcon=()=> {return (<i className='bx bxl-linkedin'/>)}
export const xingIcon=()=> {return (<i className='bx bxl-xing'/>)}
export const aboutCardIcon=()=>{return <i className={"bx bx-smile bx-md"}/> }
export const skillCardIcon=()=>{return <i className={"bx bxs-analyse bx-md"}/> }
export const contactCardIcon=()=>{return <i className={"bx bxs-contact bx-md"}/> }
export const projectCardIcon=()=>{return <i className={"bx bx-code-curly bx-md"}/> }
export const buttonProjectGitHubIcon=()=>{return <i className={"bx bxl-github bx-md"}/> }
export const buttonProjectCodeIcon=()=>{return <i className={"bx bx-code-alt bx-md"}/> }


