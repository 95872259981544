export function ContactDataLine({icon,text}) {

    return (
        <>
            {text !== null ? (

                <div className={"row row-cols-2 g-0 mb-1"}>
                    <div className={"col col-md-1"}>
                        {icon}
                    </div>
                    <div className={"col col-md-11"}>
                        {text}
                    </div>
                </div>
            ) : null
            }
        </>
    )

}