export function SkillPointDisplay({skill, visualization}){


    return (
        <div className="container m-0 row mx-0 px-0" key={"sk" + skill}>

                <div className="my-1 d-flex align-items-center fw-medium text-lowercase d-flex" style={{fontVariant: "small-caps"}}>
                    <div className="col col-md-6 ">{skill}</div>
                    <div className={"col"}>{visualization}</div>
                </div>

        </div>
    )

}