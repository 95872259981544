import {Card} from "../Shared/Card";
import React, {useContext} from "react";
import {Projectview} from "../Resume/Projectview";
import {XpTable} from "./XpTable";
import {Skillview} from "../Resume/Skillview";
import {ContactInfo} from "./ContactInfo";
import DataContext from "../../helpers/DataContext/DataContext";
import {EduTable} from "./EduTable";

export function CvDownload() {


    const {data, language} = useContext(DataContext);
    const {contact, work, education, skills, projects, headers} = data;

    if (!contact || !work || !education || !skills || !projects || !headers) {
        return null
    }

    const iconWork = index => {
        return (
            <>
                <i className={"bx bx-briefcase-alt-2 bx-md text-success"} id={"pic" + index}/>
            </>
        )
    }

    const iconContact = index => {
        return (
            <>
                <i className='bx bx-user bx-md text-success' id={"pic" + index}></i>
            </>
        )
    }

    const iconSkills = index => {
        return (
            <>
                <i className={"bx bxs-analyse bx-md text-success"} id={"pic" + index}/>
            </>
        )
    }
    const iconProjects = index => {
        return (
            <>
                <i className={"bx bx-code-curly bx-md text-success"} id={"pic" + index}/>
            </>
        )
    }

    const provideProjects = () => {

        return (
            <Projectview key={"projectview2"} data={projects}/>
        )

    }

    const provideContact = () => {
        return (
            <>
                <ContactInfo data={contact} language={language}/>
            </>
        )
    }

    const provideWorkingXP = () => {
        let icon = () => {
            return (
                <i className={"bx bx-calendar-alt text-success"}/>
            )
        }
        return (
            <>
                <XpTable key={"workingT2"} data={work} icon={icon} language={language} currentDay={headers.cvToday}/>
            </>
        )
    }

    const provideSkills = () => {
        return (
            <>
                <Skillview key={"skillT2"} data={skills} pointExplanation={headers.pointExplanation}/>
            </>
        )
    }

    const provideEduXP = () => {
        let icon = () => {
            return (
                <i className={"bx bx-calendar-alt text-success"}/>
            )
        }
        return (
            <>
                <EduTable key={"educationT2"} data={education} icon={icon} language={language}/>
            </>
        )
    }

    return (
        <div id={"cv_download"} >

            <div className={"container p-1"} style=
                {{
                    width: "210mm", // A4 width in millimeters
                    height: "290mm", // A4 height in millimeters
                    overflow: "hidden", // Prevents content from spilling out
                    position: "relative", // Needed if you're positioning children absolutely within
                    margin: "auto", // Center the div if you're displaying it on a larger screen

                    zIndex: 2
                }}>


                <div className={"row row-cols-1 g-0"} style={{marginTop: "-25px"}}>
                    <div className={"col col-md-12"}>
                        <Card key={headers.contactTitle} picture={() => iconContact(7)} formattedData={provideContact}
                              side={"left"} header={null}/>
                    </div>

                    <div className={"col"}  style={{marginTop: "-30px"}}>

                        <Card key={"dlwork" + 1} picture={() => iconWork(1)} formattedData={provideWorkingXP}
                              header={headers.experienceTitle}
                              side={"left"} id={"wor" + 1}/>
                    </div>
                    <div className={"col "} style={{marginTop: "-45px"}}>
                        <Card key={"dledu" + 2} picture={null} formattedData={provideEduXP}
                              header={null}
                              side={"left"} id={"edu" + 2}/>
                    </div>

                </div>
            </div>


            <div className={"container p-1"} style=
                {{
                    width: "210mm", // A4 width in millimeters
                    height: "290mm", // A4 height in millimeters
                    overflow: "hidden", // Prevents content from spilling out
                    position: "relative", // Needed if you're positioning children absolutely within
                    margin: "auto", // Center the div if you're displaying it on a larger screen

                    zIndex: 2
                }}>
                <div className={"row g-0 mt-3"}>


                    <div className={"col col-6"}>
                        <Card key={"dlskills" + 3} picture={() => iconSkills(3)} formattedData={provideSkills}
                              header={headers.skillsTitle}
                              side={"left"} id={"ski" + 3}/>
                    </div>

                    <div className={"col col-6"}>
                        <Card key={"dlproject" + 1} picture={() => iconProjects(1)} formattedData={provideProjects}
                              header={headers.projectsTitle}
                              side={"left"} id={"pro" + 1}/>

                    </div>


                </div>
            </div>




        </div>


    )

}