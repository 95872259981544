import React from 'react';
import {formatDateForTable} from "../../helpers/Utility/DateFormatter";

export function XpTable({data, icon: Icon, language, currentDay}) {

    if (!data) {
        return null
    }

    const sortedData = data.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));


    return (
        <>
            <div id={"all-work"} className={"card card-body bg-light py-2 border-1 border-warning"}>

                {sortedData.map((data, index) => (
                        <React.Fragment key={"xp" + index}>
                            <div className={"row my-0"}>
                                <div className={"col col-8 fw-medium"} style={{fontVariant: "small-caps"}}>
                                    {data.position}
                                </div>

                                <div className={"col gx-1"}>
                                    <div className={"row"}>
                                        <div className={"col col-2"}>
                                            <Icon/>
                                        </div>
                                        <div className={"col"}>
                                            {formatDateForTable(data.startDate, language)} - {data.endDate != null ? formatDateForTable(data.endDate, language) : currentDay}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"row"}>
                                <div className={"col col-5"}>
                                    {data.company}
                                </div>
                                {data.description !== null ?
                                    <div className={"col  text-muted ms-2 text-end"} style={{textAlign: "justify"}}>
                                        {data.description}
                                    </div>
                                    : null
                                }
                            </div>
                            <hr className={"text-warning m-2"}/>
                        </React.Fragment>
                    )
                )}

            </div>
        </>
    )
}

