import {SortedSkills} from "./SortedSkills";

export function Skillview({data, pointExplanation}) {

    if (!data) {
        return null
    }

    const programmingLanguages = () => {
        return data.filter(d => d.skillType === "prog_lang").sort((a, b) => b.points - a.points);
    }
    const web = () => {
        return data.filter(d => d.skillType === "web_tech").sort((a, b) => b.points - a.points);
    }

    const backend = () => {
        return data.filter(d => d.skillType === "backend_tech").sort((a, b) => b.points - a.points);
    }
    const soft = () => {
        return data.filter(d => d.skillType === "soft").sort((a, b) => b.points - a.points);
    }
    const language = () => {
        return data.filter(d => d.skillType === "language").sort((a, b) => b.points - a.points);
    }
    const ops = () => {
        return data.filter(d => d.skillType === "dev_ops").sort((a, b) => b.points - a.points);
    }
    const qualityAssurance = () => {
        return data.filter(d => d.skillType === "qa").sort((a, b) => b.points - a.points);
    }
    const dbManagement = () => {
        return data.filter(d => d.skillType === "db_management").sort((a, b) => b.points - a.points);
    }

    const renderSkillpoints = (points) => {

        let dots = [];

        for (let i = 0; i < 5; i++) {

            if (i < points) {
                dots.push(
                    <i className="bx bxs-sticker text-success p-1" key={`dot-active-${i}`}/>
                );
            } else {
                dots.push(
                    <i className="bx bx-sticker text-success p-1 opacity-50" key={`dot-inactive-${i}`}/>
                )
            }
        }

        return (
            <div className={"d-flex p-0 m-0"}>{dots}</div>
        );
    }

    return (
        <div id={"all_skills"} className={"container p-0"}>

            <div className={"row d-flex align-items-center"}>
                <div className={"col"}>
                    <SortedSkills visualization={renderSkillpoints} skillArray={programmingLanguages()}/>
                    <SortedSkills visualization={renderSkillpoints} skillArray={backend()}/>
                    <SortedSkills visualization={renderSkillpoints} skillArray={web()}/>
                    <SortedSkills visualization={renderSkillpoints} skillArray={ops()}/>
                    <SortedSkills visualization={renderSkillpoints} skillArray={qualityAssurance()}/>
                </div>

                <div className={"col"}>
                    <SortedSkills visualization={renderSkillpoints} skillArray={dbManagement()}/>
                    <SortedSkills visualization={renderSkillpoints} skillArray={soft()}/>
                    <SortedSkills visualization={renderSkillpoints} skillArray={language()}/>
                </div>

            </div>
            <div className={"row mt-3 mx-0 text-center"}>
                <div className={"border-top border-1 border-warning fw-light"} style={{fontSize: "0.7em"}}>
                    {pointExplanation}
                </div>
            </div>
        </div>
    )

}