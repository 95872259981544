import React from "react";

export function Header({firstName}) {
    return (

        <div className={"header-background"}>
            <div className={"container d-flex justify-content-end"}>
                <div className={"name-title text-success text-lowercase"}>
                    <span>{firstName}</span>
                </div>
            </div>
        </div>
    )
}