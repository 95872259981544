import React, {useEffect, useState} from 'react';

export function PrivacyToast() {
    const [show, setShow] = useState(true);

    useEffect(() => {
        // Show the toast after a brief delay when the component mounts
        const timer = setTimeout(() => setShow(false), 3000);
        return () => clearTimeout(timer); // Clean up the timer when the component unmounts
    }, []);

    if (!show) {
        return null;
    }

    return (
        <div className="toast show align-items-center bg-warning border-0 " role="alert" aria-live="assertive"
             aria-atomic="true" style={{position: 'absolute'}}>
            <div className="d-flex">
                <div className="toast-body">
                    This site does not use cookies or collect personal data.
                </div>
            </div>
        </div>
    );
}
