import React, {useContext} from "react";
import dataContext from "../../helpers/DataContext/DataContext";

export function Navigation({currentSection, disabled, downloadHandler}) {

    const {data}=useContext(dataContext);
    const {headers}=data;

    return <nav className="navbar bg-light navbar-expand-sm">
        <div className="container-fluid mt-2 fs-4 text-lowercase"
             style={{zIndex: 3, fontVariant: "small-caps"}}>
            <div className={"row"}>
                <div className={"col"}>
                    <div className="nav-title text-lowercase text-success">
                        {currentSection}
                    </div>
                </div>
                <div className={"col"} >
                    <button className="navbar-toggler border-1 border-warning text-warning"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup"
                            aria-controls="navbarNavAltMarkup"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">

                        <div className="navbar-nav fw-semibold">

                            <a className="nav-link me-3"
                               href={"#about"}>{headers.navAbout}</a>

                            <a className="nav-link me-3" aria-current="page"
                               href={"#resume"}>{headers.navResume}</a>

                            <a className="nav-link me-3"
                               href={"#contact"}>{headers.navContact}</a>

                            <button className={"btn fs-5 fw-semibold border-0 text-start pb-0"}
                                    disabled={disabled}
                                    type={"button"}
                                    onClick={downloadHandler}>
                                {disabled ? (
                                    <i className="bx bx-loader-circle text-warning bx-spin bx-md px-2 mx-5"/>
                                ) : (

                                    <div className="text-success text-uppercase" style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        fontVariant: "small-caps"
                                    }}> {headers.downloadButton}
                                        <i className="bx bx-download text-success ms-1"/>
                                    </div>

                                )

                                }
                            </button>

                        </div>
                    </div>

                </div>
            </div>
        </div>


    </nav>;
}