import {ProjectLinkButton} from "./ProjectLinkButton";

export function Projectview({data}) {

    if (!data) {
        return null
    }


    const renderButtonLink = (link, isGithub) => {

        return (
            <ProjectLinkButton link={link} isGithub={isGithub}/>
        )

    }

    const displayText = (data) => {
        if (data.description === null && data.techStack !== null) {
            return data.techStack;
        } else if (data.description !== null) {
            return data.description;
        } else {
            return null;
        }

    }

    return (


        <div id={"all_projects"} className={"row g-1"}>
            <div className={"col col-12 "}>
                {data.map((data, index) => {

                    return (
                        <div className={"card border-warning bg-light mb-1"} key={"c1" + index}>
                            <div className={"card-body p-0 m-0"}>
                                <div className={"row m-2 mb-1"}>
                                    <div className={"col fw-medium fs-5"} style={{fontVariant: "small-caps"}}>
                                        {data.name}
                                    </div>

                                    <div className={"col col-2 text-end"}>
                                        {renderButtonLink(data.link, data.gitHub)}
                                    </div>
                                </div>

                                {data.description !== null &&
                                    <div className={"row ms-3 me-3 mb-3 mt-0 text-muted"}
                                         style={{textAlign: "justify"}}>
                                        {data.description}
                                        {data.techStack !== null &&
                                            <div className={"row pt-1 fw-light"}
                                                 style={{textAlign: "justify"}}>
                                                {data.techStack}
                                            </div>
                                        }
                                    </div>
                                }

                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )


}