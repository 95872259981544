import {formatDateForContact} from "../../helpers/Utility/DateFormatter";
import {ContactDataLine} from "../Contact/ContactDataLine";
import {addressIcon, birthdayIcon, mailIcon, phoneIcon, webIcon} from "../../helpers/Constants/Icons";

export function ContactInfo({data, language}) {




    return (
        <div id={"infoSmall"} className={"container"}>
            <div className={"row row-cols-3 mt-0 pt-0 d-flex align-items-center"}>


                <div className={"col col-4 pt-0 d-flex justify-content-center ps-4"}>
                    <img alt={""} src={`${process.env.PUBLIC_URL}/port_small.png`} style={{height: "150px", zIndex:"-1"}}
                         className={"rounded-3 m-0 p-0"}/>
                </div>

                <div className={"col col-4 m-0 p-0"}>

                    <h2 className={"text-success "} style={{fontVariant:"small-caps", marginLeft:"-50px", letterSpacing:"4px"}}>{data.firstName} {data.lastName}</h2>

                    <ContactDataLine icon={addressIcon()} text={data.address}/>


                    <ContactDataLine icon={birthdayIcon()} text={formatDateForContact(data.birthday, language)}/>
                </div>
                <div className={"col col-4 m-0 p-0 pt-5"}>
                    <ContactDataLine icon={webIcon()} text={data.webUrl}/>
                    <ContactDataLine icon={mailIcon()} text={data.email}/>
                    <ContactDataLine icon={phoneIcon()} text={data.phone}/>

                </div>

            </div>
        </div>
    )
}