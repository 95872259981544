

export function formatDateForTable(date, language) {

    const dateObj = new Date(date);

    if (language==="ENG"){

        return dateObj.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric'
        });

    }else if (language==="GER"){
        return dateObj.toLocaleDateString('de-DE', {
            month: 'short',
            year: 'numeric'
        });
    }


}

export function formatDateForContact(dateString, language) {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    if (language === "ENG") {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const monthName = monthNames[monthIndex];
        return `${monthName} ${day}, ${year}`;

    } else if (language === "GER") {
        const dayPadded = day.toString().padStart(2, '0');
        const monthPadded = (monthIndex + 1).toString().padStart(2, '0');
        return `${dayPadded}.${monthPadded}.${year}`;
    }
}