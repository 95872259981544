import {Card} from "../Shared/Card";
import {aboutCardIcon} from "../../helpers/Constants/Icons";
import {AboutDisplay} from "./AboutDisplay";
import {useContext} from "react";
import DataContext from "../../helpers/DataContext/DataContext";

export function About() {

    const {data}=useContext(DataContext);
    const {headers}=data;

    const comp = () => {
        return (
            <AboutDisplay/>
        )
    }

    return (
        <div id={"about"} className={"container m-0 p-0"}>

            <Card id={"about"} header={headers.aboutTitle} side={"left"} picture={() => aboutCardIcon()}
                  formattedData={comp}/>

        </div>
    )

}