import {useContext} from "react";
import DataContext from "../../helpers/DataContext/DataContext";

export function ContactDisplay(){
    const {data} = useContext(DataContext);

    const {contact}=data;

    if (!contact) {
        return null
    }

    return  (
        <div className="card card-body bg-light p-4 border-1 border-warning">
            <div className="d-flex flex-column align-items-center justify-content-center">
                {/* Contact Info */}
                <div className="d-flex align-items-center mb-3">
                    <i className='bx bx-home-alt-2 pe-2 bx-sm text-success' />
                    {contact.address}
                </div>
                <div className="d-flex align-items-center mb-3">
                    <i className='bx bx-envelope pe-2 bx-sm text-success' />
                    {contact.email}
                </div>

                {/* Social Links */}
                <div className="d-flex justify-content-center gap-4">
                    {contact.githubUrl && (
                        <a className="btn border-0 bg-transparent" href={contact.githubUrl}>
                            <i className='bx bxl-github bx-sm text-success'></i>
                        </a>
                    )}
                    {contact.webUrl && (
                        <a className="btn border-0 bg-transparent" href={contact.webUrl}>
                            <i className='bx bx-window bx-sm text-success'></i>
                        </a>
                    )}
                    {contact.linkedinUrl && (
                        <a className="btn border-0 bg-transparent" href={contact.linkedinUrl}>
                            <i className='bx bxl-linkedin bx-sm text-success'></i>
                        </a>
                    )}
                    {contact.xingUrl && (
                        <a className="btn border-0 bg-transparent" href={contact.xingUrl}>
                            <i className='bx bxl-xing bx-sm text-success'></i>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
}