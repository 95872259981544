import {ABOUTTEXT, CONTACTDATA, EDUCATIONDATA, PROJECTSDATA, SKILLSDATA, WORKDATA} from "../Constants/Endpoints";

export async function fetchContactData(language) {
var id;
    if(language==="ENG"){
         id=2;
    }else{
        id=1;
    }

    const response = await fetch(CONTACTDATA.replace("{id}", id));
    const data = await response.json();

    return data;

}

export async function fetchWorkData(language) {
    var id;
    if(language==="ENG"){
        id=2;
    }else{
        id=1;
    }
    const response = await fetch(WORKDATA.replace("{id}", id));
    const data = await response.json();
    return data;

}

export async function fetchEducationData(language) {
    var id;
    if(language==="ENG"){
        id=2;
    }else{
        id=1;
    }
    const response = await fetch(EDUCATIONDATA.replace("{id}", id));
    const data = await response.json();
    return data;

}

export async function fetchSkillData(language) {
    var id;
    if(language==="ENG"){
        id=2;
    }else{
        id=1;
    }
    const response = await fetch(SKILLSDATA.replace("{id}", id));
    const data = await response.json();
    return data;

}

export async function fetchProjectData(language) {
    var id;
    if(language==="ENG"){
        id=2;
    }else{
        id=1;
    }
    const response = await fetch(PROJECTSDATA.replace("{id}", id));
    const data = await response.json();
    return data;

}

export async function fetchAboutText(language) {
    var id;
    if(language==="ENG"){
        id=2;
    }else{
        id=1;
    }
    const response = await fetch(ABOUTTEXT.replace("{id}", id));
    const data = await response.json();
    return data;

}