import React, {useContext, useEffect, useRef} from "react";
import {useIntersectionObserver} from "../../helpers/Utility/IntersectionObserver";
import {About} from "../About/About";
import {Resume} from "../Resume/Resume";
import {Contact} from "../Contact/Contact";
import {CvDownload} from "../CV_Download/CvDownload";
import dataContext from "../../helpers/DataContext/DataContext";

export function ContentHolder({handleSectionChange}) {

    const {data}=useContext(dataContext);
    const {headers}=data;

    const resumeRef = useRef(null);
    const aboutRef = useRef(null);
    const contactRef = useRef(null);

    const resumeVisible = useIntersectionObserver(resumeRef, {threshold: 0.8});
    const aboutVisible = useIntersectionObserver(aboutRef, {threshold: 0.9});
    const contactVisible = useIntersectionObserver(contactRef, {threshold: 0.2});

    useEffect(() => {
        if (resumeVisible) handleSectionChange(headers.navResume);
        else if (aboutVisible) handleSectionChange(headers.navAbout);
        else if (contactVisible) handleSectionChange(headers.navContact);
    }, [resumeVisible, aboutVisible, contactVisible]);

    return (
        <div id="siteBody">

            <div id="about" ref={aboutRef} className={"pb-5"}>
                <About/>
            </div>
            <div id="resume" ref={resumeRef} className={"pb-5"}>
                <Resume/>
            </div>
            <div id="contact" ref={contactRef} className={"py-5"}>
                <Contact/>
            </div>
            <div id={"CV-download"} style={{
                               position: 'absolute',
                                left: '-9999px',
                                top: 'auto',
                                width: '1px',
                                height: '1px',
                                overflow: 'hidden'

                                }}>
                <CvDownload/>
            </div>

            <div className={"text-end sticky-bottom "} style={{marginRight: "-10px"}}>
                <button className={"btn p-0 text-warning border-0"} type={"button"}
                        onClick={() => window.scrollTo({top: 0})}>
                    <i className='bx bx-up-arrow-alt m-0 p-0 bx-lg'></i>
                </button>
            </div>

        </div>

    )
}