import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import DataContext from "./helpers/DataContext/DataContext";
import {PrivacyToast} from "./components/Shared/PrivacyToast";
import {LoadingAnimation} from "./components/Shared/LoadingAnimation";
import {Header} from "./components/Shared/Header";
import {Navigation} from "./components/Shared/Navigation";
import {ContentHolder} from "./components/Shared/ContentHolder";
import {cvPdfConverter} from "./helpers/Utility/cvPdfConverter";
import {defaultNav as navDefault} from "./helpers/Constants/DefaultWordings";


function App() {

    const {data, language, setLanguage, fetchingData} = useContext(DataContext)

//loading needed when language change occurs

const [languageButton, setLanguageButton] =useState("GER");
    useEffect(() => {
        setShowLoading(true);

        if (language === "ENG") {
            setLanguageButton("GER");
            setCurrentSection(navDefault.ENG)
        } else {
            setLanguageButton("ENG");
            setCurrentSection(navDefault.GER)
        }


    }, [language]);

    useEffect(()=>{
        if(fetchingData===false){
            setShowLoading(false)
        }else{
            setShowLoading(true)
        }
    },[fetchingData]);


    const [currentSection, setCurrentSection] = useState("Welcome");

    const [isDownloading, setIsDownloading] = useState(false);

    const [showLoading, setShowLoading] =useState(false)

    const {contact} = data;
    if (!contact || showLoading) {
        return (<LoadingAnimation/>)
    }


    const handleCVDownload = (lastName, firstName) => {
        setIsDownloading(true);

        try {
            cvPdfConverter(lastName, firstName, "cv_download")
        } catch (error) {
            console.log("pdf download:" + error);
        }

        setIsDownloading(false);

    };




    return (

        <>
            <div className={"d-flex justify-content-center"}>
                <PrivacyToast/>
            </div>

            <div id={"cv-full"}>

                <Header firstName={contact.firstName}/>
                <div className={"text-start"} style={{position: "fixed", bottom: '1%', left: '1%', zIndex: "10"}}>
                    <button className={"btn btn-secondary rounded-circle fw-medium fs-5 text-lowercase"}
                            type={"button"} style={{fontVariant: "small-caps"}}
                            onClick={() => setLanguage(languageButton)}>{languageButton}</button>
                </div>

                <div className={"col mb-3"} style={{marginTop: "-15vh", position: "relative", zIndex: 2}}>

                    <div className={"container rounded-3 bg-light border-0"}>

                        <Navigation currentSection={currentSection} disabled={isDownloading}
                                    downloadHandler={() => handleCVDownload(contact.lastName, contact.firstName)}/>

                        <ContentHolder handleSectionChange={setCurrentSection}/>

                    </div>


                </div>


            </div>
        </>

    )

}

export default App;
