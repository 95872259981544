import React from "react";
import {formatDateForTable} from "../../helpers/Utility/DateFormatter";

export function EduTable({data, icon: Icon, language}) {

    if (!data) {
        return (<div>Loading</div>)
    }
    const sortedData = data.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    return (
        <>
            <div id={"all-edu"} className={"card card-body bg-light py-2 border-1 border-warning"} >

                {sortedData.map((data, index) => (
                        <React.Fragment key={"edu" + index}>
                            <div className={"row my-0"}>

                                <div className={"col col-4 fw-medium"} style={{fontVariant: "small-caps"}}>
                                    {data.degree !== "" ?

                                        data.degree

                                        : null
                                    }
                                </div>
                                <div className={"col col-4"}>
                                    {data.school}
                                </div>

                                <div className={"col gx-1"}>
                                    <div className={"row"}>
                                        <div className={"col col-2"}>
                                            <Icon/>
                                        </div>
                                        <div className={"col"}>
                                    {formatDateForTable(data.startDate, language)} - {formatDateForTable(data.endDate, language)}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {data.description !== null ?
                                <div className={"row my-0 px-5 text-muted"} style={{textAlign: "justify"}}>
                                    {data.description}
                                </div>
                                : null
                            }
                            <hr className={"text-warning m-2"}/>
                        </React.Fragment>
                    )
                )}

            </div>
        </>
    )
}