import {buttonProjectCodeIcon, buttonProjectGitHubIcon} from "../../helpers/Constants/Icons";

export function ProjectLinkButton({link, isGithub}){

    if (link !== null) {
        return (
            <a href={link} className={"btn btn-secondary text-success m-0 p-0"} target="_blank" rel="noopener noreferrer">
                {isGithub ? buttonProjectGitHubIcon() :
                    buttonProjectCodeIcon()}
            </a>
        );
    } else {
        return (
            <button disabled={true} className={"btn bg-transparent opacity-25 border-0 m-0 p-0 "}>
                {buttonProjectCodeIcon()}
            </button>
        );
    }

}