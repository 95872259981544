import {SkillPointDisplay} from "./SkillPointDisplay";

export function SortedSkills({skillArray, visualization}) {
    return (
        <div className={"mb-1 mx-0 px-0 border rounded-3 border-1 bg-light border-warning"}>
            {skillArray.map((data, index) => {
                    return (
                        <div className={"col"} key={"be" + index}>
                            <SkillPointDisplay skill={data.name}
                                               visualization={visualization(data.points)}/>
                        </div>
                    )
                }
            )}
        </div>
    )
}