import {Card} from "../Shared/Card";
import {contactCardIcon} from "../../helpers/Constants/Icons";
import {ContactDisplay} from "./ContactDisplay";
import {useContext} from "react";
import DataContext from "../../helpers/DataContext/DataContext";

export function Contact() {

    const {data}=useContext(DataContext);
    const {headers}=data;

    const comp = () => {
        return (
            <ContactDisplay/>
        )
    }

    return (
        <div id={"contact-info"} className={"container m-0 p-0"}>

            <Card id={"contact"} header={headers.contactTitle} side={"left"} picture={() => contactCardIcon()}
                  formattedData={comp}/>

        </div>

    )


}