import "../../custom.scss";
import React, {useContext} from "react";
import {Card} from "../Shared/Card";
import {Skillview} from "./Skillview";
import {Projectview} from "./Projectview";
import DataContext from "../../helpers/DataContext/DataContext";
import {projectCardIcon, skillCardIcon} from "../../helpers/Constants/Icons";

export function Resume() {

    const {data} = useContext(DataContext);
    const { skills, projects, headers } = data;

    if (!skills || !projects ||!headers) {
        console.log(skills)
        return null;
    }


    const provideProjects = () => {

        return (
            <Projectview key={"projectview1"} data={projects}/>
        )

    }

    const provideSkills = () => {

        return (
            <>
                <Skillview key={"skillT1"} data={skills} pointExplanation={headers.pointExplanation}/>
            </>
        )

    }


    return (

        <div id={"CV-partial"} className={"container m-0 p-0"}>
            <div className={"row row-cols-xxl-2 row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-sm-1 g-0"}>
                <div className={"col m-0 p-0"}>

                    <Card key={"c-r" + 1} picture={() => projectCardIcon()} formattedData={provideProjects}
                          header={headers.projectsTitle}
                          side={"left"} id={"card-pr"}/>

                </div>
                <div className={"col m-0 p-0"}>

                    <Card key={"c-l" + 3} picture={() => skillCardIcon()} formattedData={provideSkills}
                          header={headers.skillsTitle}
                          side={"right"} id={"card-sk"}/>

                </div>
            </div>
        </div>

    )
}