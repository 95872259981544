import html2pdf from "html2pdf.js";

export function cvPdfConverter(lastName, firstName, elementName){
    const element = document.getElementById(elementName);
    if (!element) {
        throw new Error(`Element not found: ${elementName}`);
    }

    const options = {
        margin: 0,
        filename: `cv_${lastName}_${firstName}.pdf`,
        image: {type: 'jpeg', quality: 1},
        html2canvas: {scale: 1.5, logging: true, dpi: 96, letterRendering: true},
        jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'}
    };

    html2pdf().set(options).from(element).save()
}