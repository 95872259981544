import React, {useEffect, useState} from 'react';
import DataContext from './DataContext';
import {
    fetchAboutText,
    fetchContactData,
    fetchEducationData,
    fetchProjectData,
    fetchSkillData,
    fetchWorkData
} from "./DataHandler";

export const DataProvider = ({children}) => {

    const[fetchingData, setFetchingData] =useState(false);

    const [language, setLanguage] = useState('GER');

    const [data, setData] = useState({
        contact: null,
        work: null,
        education: null,
        skills: null,
        projects: null,
        about: null,
        headers: null
    });

    useEffect(() => {
setFetchingData(true);
        const fetchData = async () => {
            try {

                let translation = await import("../Constants/germanWording");
                if (language === "ENG") {
                    translation = await import("../Constants/englishWording")
                }

                const contact = await fetchContactData(language);
                const work = await fetchWorkData(language);
                const education = await fetchEducationData(language);
                const skills = await fetchSkillData(language);
                const projects = await fetchProjectData(language);
                const about = await fetchAboutText(language);
                setData({
                    contact: contact,
                    work: work,
                    education: education,
                    skills: skills,
                    projects: projects,
                    about: about,
                    headers: translation.wording

                });


            } catch (error) {
                console.error("Error fetching data:", error);

            }finally {
                setFetchingData(false)
            }
        };

        fetchData();



    }, [language]);

    return (
        <DataContext.Provider value={{data, language, setLanguage, fetchingData}}>
            {children}
        </DataContext.Provider>
    );
};