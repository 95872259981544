import {useContext} from "react";
import DataContext from "../../helpers/DataContext/DataContext";

export function AboutDisplay() {
    const {data} = useContext(DataContext);
    const {about, contact} = data;

    if (!about || !contact) {
        return null
    }

    const aboutFormatted = about.aboutText.replace("{firstName}", contact.firstName).split("\r\n").map((line, index) => (
        <p key={index}>{line}</p>
    ));

    return (

        <div className="card card-body bg-light py-3 px-2 m-0 border-1 border-warning">
            <div className="row d-flex align-items-center m-0 p-0">

                <div className="col" style={{textAlign: "justify"}}>
                    {/* Float the image to the right on screens smaller than xl */}
                    <div className="d-xxl-none float-end ms-3 mb-3 ">
                        <div className="border bg-warning border-1 border-warning rounded-3 " style={{overflow: "hidden"}}>
                            <img className="portrait" alt="portrait" src={`${process.env.PUBLIC_URL}/portrait.jpg`}
                            />
                        </div>
                    </div>
                    {aboutFormatted}
                </div>

                <div className="col col-4 d-none d-xxl-flex align-items-center justify-content-center">
                    <div className="border bg-warning border-1 border-warning rounded-3 " style={{overflow: "hidden"}}>

                            <img className="portrait-big" alt="portrait"
                                 src={`${process.env.PUBLIC_URL}/portrait.jpg`}/>

                    </div>
                </div>
            </div>
        </div>

    )

}